'use client';

import React from 'react';
import { useIsMobile } from 'src/app/(providers)/DeviceContext';
import { Snackbar } from './Snackbar';

type Props = React.ComponentPropsWithRef<'div'>;

interface SnackbarContextState {
  showSnackbar: (
    message: string,
    variant: 'success' | 'error' | 'warning' | 'info'
  ) => void;
  items: {
    message: string;
    id: string;
    variant: 'success' | 'error' | 'warning' | 'info';
  }[];
}

const SnackbarContext = React.createContext<SnackbarContextState>({
  items: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showSnackbar: () => {},
});

const generateId = () => Math.random().toString(36).substring(2, 8);

export function SnackbarProvider(props: Props) {
  const [items, setItems] = React.useState<SnackbarContextState['items']>([]);
  const isMobile = useIsMobile();

  const maxItems = isMobile ? 1 : 5;

  const showSnackbar = React.useCallback(
    (message: string, variant: 'success' | 'error' | 'warning' | 'info') => {
      const id = generateId();
      setItems((prev) =>
        prev.length >= maxItems
          ? [...prev.slice(1), { id, message, variant }]
          : [...prev, { id, message, variant }]
      );
    },
    []
  );

  const closeSnackbar = React.useCallback(
    (id: string) => () => {
      setItems((prev) => prev.filter((item) => item.id !== id));
    },
    []
  );

  return (
    <SnackbarContext.Provider
      value={{
        items: items,
        showSnackbar,
      }}
    >
      <div className="fixed inset-x-4 top-16 z-[300] flex flex-col items-end gap-4 overflow-hidden sm:left-auto sm:right-4">
        {items.map((item) => (
          <Snackbar
            onClose={closeSnackbar(item.id)}
            key={item.id}
            variant={item.variant}
            message={item.message}
          />
        ))}
      </div>
      {props.children}
    </SnackbarContext.Provider>
  );
}

export const useSnackbar = () => {
  const context = React.useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context.showSnackbar;
};
