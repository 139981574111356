import clsx, { ClassValue } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      h: [{ h: ['footer', 'page', 'navbar'] }],
      'min-h': [{ 'min-h': ['page'] }],
      'text-transform': [
        'uppercase',
        'lowercase',
        'capitalize',
        'normal-case',
        'small-caps',
      ],
    },
  },
});

export const cn = (...classes: ClassValue[]) => twMerge(clsx(classes));
