'use client';

import React from 'react';
import { Moon, Sun } from 'lucide-react';
import { Button } from 'src/components/Button/Button';
import { Translated } from 'src/i18n/TranslationsProvider';
import { cn } from 'src/utils/cn';

const DARK_THEME_CLASS = 'dark' as const;
const THEME_STORAGE_KEY = 'dux.theme';
const DARK_THEME_MEDIA_QUERY = '(prefers-color-scheme: dark)';

type ThemeMode = 'dark' | 'light';

type Props = React.PropsWithChildren;

const updateThemeColorTag = () => {
  const el = document.querySelector('meta[name="theme-color"]');
  const color = getComputedStyle(document.body).getPropertyValue(
    '--background'
  );
  el?.setAttribute('content', `rgb(${color.trim().split(' ').join(',')})`);
};

const getUserPreferredTheme = (): ThemeMode => {
  const savedTheme = getSavedThemeFromStorage();
  if (savedTheme) {
    return savedTheme;
  }
  if (window.matchMedia && window.matchMedia(DARK_THEME_MEDIA_QUERY).matches) {
    return 'dark';
  }
  return 'light';
};

const handlePreferredColorSchemaChange = (event: MediaQueryListEvent) => {
  setTheme(event.matches ? 'dark' : 'light');
};

const useForceRender = () => {
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  return forceUpdate;
};

const saveSelectedThemeToStorage = (theme: ThemeMode) => {
  window.localStorage.setItem(THEME_STORAGE_KEY, theme);
};

const getSavedThemeFromStorage = () => {
  const value = window.localStorage.getItem(THEME_STORAGE_KEY);
  return value ? (value as ThemeMode) : undefined;
};

export const getCurrentTheme = (): ThemeMode =>
  document.body.classList.contains(DARK_THEME_CLASS) ? 'dark' : 'light';

export const setTheme = (theme?: ThemeMode) => {
  if (!theme) {
    // should take opposite value of current theme
    theme = getCurrentTheme() === 'dark' ? 'light' : 'dark';
  }
  if (theme === getCurrentTheme()) {
    return;
  }
  if (theme === 'dark') {
    document.body.classList.add(DARK_THEME_CLASS);
  } else {
    document.body.classList.remove(DARK_THEME_CLASS);
  }
  updateThemeColorTag();
};

export const useThemeChange = (onChange: (theme: ThemeMode) => void) => {
  React.useEffect(() => {
    const observer = new MutationObserver(() => {
      onChange(getCurrentTheme());
    });
    observer.observe(document.body, {
      attributeFilter: ['class'],
      attributes: true,
      characterData: false,
      childList: false,
    });
    return () => {
      observer.disconnect();
    };
  }, [onChange]);
};

export const useCurrentTheme = () => {
  const [value, setValue] = React.useState<ThemeMode>(getCurrentTheme());

  useThemeChange(setValue);

  return value;
};

export function ThemeToggle({
  className,
  withLabel = true,
  variant = 'secondary',
  square = true,
  onClick,
  ...props
}: React.ComponentProps<typeof Button> & { withLabel?: boolean }) {
  const rerender = useForceRender();
  const theme = getCurrentTheme();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setTheme();
    saveSelectedThemeToStorage(getCurrentTheme());
    onClick?.(e);
    rerender();
  };

  return (
    <Button
      square={square}
      variant={variant}
      onClick={handleClick}
      className={cn('gap-4', className)}
      {...props}
    >
      {theme === 'dark' && <Sun />}
      {theme === 'light' && <Moon />}
      {withLabel && (
        <>
          {theme === 'dark' ? (
            <Translated id="light-mode" />
          ) : (
            <Translated id="dark-mode" />
          )}
        </>
      )}
    </Button>
  );
}

if (typeof window !== 'undefined') {
  const preferredTheme = getUserPreferredTheme();
  if (preferredTheme === 'dark') {
    document.body.classList.add(DARK_THEME_CLASS);
  }
  updateThemeColorTag();
}

export function ThemeProvider({ children }: Props) {
  React.useEffect(() => {
    window
      .matchMedia(DARK_THEME_MEDIA_QUERY)
      .addEventListener('change', handlePreferredColorSchemaChange);

    return () => {
      window
        .matchMedia(DARK_THEME_MEDIA_QUERY)
        .removeEventListener('change', handlePreferredColorSchemaChange);
    };
  }, []);

  return <>{children}</>;
}
