'use client';

import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { getSupabaseBrowserClient } from 'src/utils/supabase/getSupabaseBrowserClient';

export function AnalyticsIdentifyUser() {
  const supabase = getSupabaseBrowserClient();
  const posthog = usePostHog();

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        if (posthog.get_distinct_id() !== session.user.id) {
          posthog.identify(session.user.id, {
            authorized: true,
          });
        }
      } else {
        posthog.reset();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return null;
}
