import { create } from 'zustand';

interface Store {
  user?: {
    name: string;
    accessToken: string;
    id: string;
    avatar_url?: string;
  };
  actions: {
    setUser: (user: Store['user']) => void;
    resetUser: () => void;
  };
}

const useStore = create<Store>()((set) => ({
  actions: {
    resetUser: () => set(() => ({ user: undefined })),
    setUser: (user) => set(() => ({ user })),
  },
}));

export const useUserActions = () => useStore((state) => state.actions);
export const useUser = () => useStore((state) => state.user);
