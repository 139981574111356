import React from 'react';
import { X as CloseIcon } from 'lucide-react';
import { useIsMobile } from 'src/app/(providers)/DeviceContext';
import { cn } from 'src/utils/cn';

type Props = {
  variant: 'success' | 'error' | 'warning' | 'info';
  message: string;
  onClose: () => void;
};

const ANIMATION_DURATION = 200;

export function Snackbar({ message, onClose, variant }: Props) {
  const isMobile = useIsMobile();
  const rootRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const root = rootRef.current;
    if (root) {
      if (!isMobile) {
        const width = root.getBoundingClientRect().width;
        root.style.width = `${width}px`;
      }
      root.style.transform = 'translateX(0)';
    }
  }, []);

  React.useEffect(() => {
    if (variant === 'error') {
      return;
    }
    const timeout = setTimeout(() => {
      const root = rootRef.current;
      if (root) {
        root.style.transform = 'translateX(100%)';
        window.setTimeout(() => onClose(), ANIMATION_DURATION);
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      ref={rootRef}
      className={cn(
        'relative flex w-full items-center justify-between gap-4 bg-backgroundLight/60 px-3 py-2 backdrop-blur dark:bg-primary/20 sm:max-w-xs',
        'translate-x-full transform transition-all ease-out'
      )}
      style={{
        transform: 'translateX(100%)',
        transitionDuration: `${ANIMATION_DURATION}ms`,
      }}
    >
      {/* animate width from 100% to 0 */}
      {variant !== 'error' && (
        <div
          className={cn(
            'absolute bottom-0 right-0 -z-[1] h-[2px] animate-[shrink_3s_linear_forwards] bg-secondary'
          )}
          style={{ width: '100%' }}
        />
      )}
      <div>{message}</div>
      <CloseIcon onClick={onClose} className="cursor-pointer hover:stroke-2" />
      <div
        className={cn('absolute left-0 h-full w-2', {
          'bg-green-600': variant === 'success',
          'bg-orange-600': variant === 'warning',
          'bg-red-600': variant === 'error',
        })}
      />
    </div>
  );
}
