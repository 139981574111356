'use client';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

const breakpoints = {
  desktop: 1280,
  mobile: 640,
  tablet: 1024,
} as const;

interface DeviceContextState {
  device: keyof typeof breakpoints;
  innerHeight: number;
  innerWidth: number;
  resolution: string;
}

type Props = React.PropsWithChildren;

const DeviceContext = createContext<DeviceContextState>({
  device: 'desktop',
  innerHeight: 0,
  innerWidth: 0,
  resolution: '',
});

export const getDevice = (): keyof typeof breakpoints => {
  const width = window.innerWidth;
  if (width < breakpoints.mobile) {
    return 'mobile';
  }
  if (width < breakpoints.tablet) {
    return 'tablet';
  }
  return 'desktop';
};

export default function DeviceContextProvider({ children }: Props) {
  const [device, setDevice] =
    useState<DeviceContextState['device']>(getDevice());
  const [height, setHeight] = useState<DeviceContextState['innerHeight']>(
    window.innerHeight
  );
  const [width, setWidth] = useState<DeviceContextState['innerHeight']>(
    window.innerHeight
  );

  const handleResize = useCallback(
    debounce(
      throttle(() => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
        setDevice(getDevice());
      }, 100),
      100
    ),
    []
  );

  useEffect(function addEventListener() {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DeviceContext.Provider
      value={{
        device: device,
        innerHeight: height,
        innerWidth: width,
        resolution: `${window.innerWidth}x${window.innerHeight}`,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
}
export const useIsMobile = () => useContext(DeviceContext).device === 'mobile';
export const useDeviceHeight = () => useContext(DeviceContext).innerHeight;
export const useDeviceWidth = () => useContext(DeviceContext).innerWidth;
export const useDeviceSize = () => {
  const { innerHeight, innerWidth } = useContext(DeviceContext);

  return {
    height: innerHeight,
    width: innerWidth,
  };
};
