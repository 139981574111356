'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (
  typeof window !== 'undefined' &&
  process.env.NEXT_PUBLIC_POSTHOG_KEY &&
  process.env.NEXT_PUBLIC_POSTHOG_HOST &&
  process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true'
) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: '/api/ingest',
    autocapture: false,
    capture_pageleave: true,
    capture_pageview: false,
    disable_session_recording: true,
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') {
        posthog.debug();
      }
    },
    persistence: 'localStorage',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  });
}

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  if (!posthog) {
    return children;
  }

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
