import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.10_next@14.2.5_@babel+core@7.24.9_@playwright+test@1.44.1_react-do_niri3vqj3rxg4lag2fhgytotva/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/ClashDisplay.woff2\",\"variable\":\"--font-clash\"}],\"variableName\":\"clashDisplayFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.9_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/Melodrama.woff2\",\"variable\":\"--font-melodrama\"}],\"variableName\":\"melodramaFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(providers)/DeviceContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/src/app/(providers)/ThemeProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/styles/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsPageView"] */ "/vercel/path0/src/modules/analytics/analytic-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsIdentifyUser"] */ "/vercel/path0/src/modules/analytics/analytics-identify-user.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnalyticsProvider"] */ "/vercel/path0/src/modules/analytics/analytics-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/vercel/path0/src/modules/analytics/web-vitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SnackbarProvider"] */ "/vercel/path0/src/modules/SnackbarProvider/SnackbarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupabaseProvider"] */ "/vercel/path0/src/modules/Supabase/SupabaseProvider.tsx");
