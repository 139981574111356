'use client';

import { createContext, useContext } from 'react';
import get from 'lodash/get';
import { Dictionary } from './getTranslations';
import { ValidLocale } from './locale';

type ContextValue = {
  translations: Dictionary;
  language: ValidLocale;
};

const TranslationsContext = createContext<ContextValue | null>(null);

type ProviderProps = {
  value: ContextValue;
  children: React.ReactNode;
};

export const TranslationsProvider = ({ value, children }: ProviderProps) => {
  return (
    <TranslationsContext.Provider value={value}>
      {children}
    </TranslationsContext.Provider>
  );
};

export const useTranslations = (key: NestedKeys<Dictionary>) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return get(useContext(TranslationsContext)!.translations, key);
};

export const useCurrentLanguage = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(TranslationsContext)!.language;
};

export function Translated({ id }: { id: NestedKeys<Dictionary> }) {
  const label = useTranslations(id);
  return label;
}
